// 不要编辑domainConfig.js
// 修改配置请编辑它的兄弟domainConfig.template.js
export const BACKEND_BASE_URL = 'https://wx.colyz.cn'
export const TEMP_PLATFORM = 'wx489404bcdb6dfa80' // 三方平台id
export const BILLING_APP = 'https://zhongwenopen.oss-cn-hangzhou.aliyuncs.com/config/pay.json' // 计费公号ID地址
export const WXCP_URL = 'https://%APPID%.wx.colyz.cn/' // 指定公号地址
export const ICP_NO = '粤ICP11047445号' // ICP证号
export const SP_NO = '' // SP证号
export const WEB_AUTHOR_URL = 'https://wxa.colyz.cn/author/index.html'
export const WEB_PAY_URL = 'https://wxa.colyz.cn/author/pay/index.html' // 公众号计费URL
