import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import Taro, { getStorageSync as _getStorageSync } from "@tarojs/taro-h5";
import { GLOBAL_CONFIG } from "./config";

Sentry.init({
  dsn: 'https://a33980f72c9c48d09db2269b988e2c27@sentry.17k.com/12',
  integrations: [new BrowserTracing({
    shouldCreateSpanForRequest: url => {
      return false;
    }
  })],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2
});
const uuid = _getStorageSync(GLOBAL_CONFIG.wy_uuid);
if (uuid) {
  Sentry.setUser({
    id: uuid
  });
}