import { isProd } from '@/utils/config'
import * as DOMAIN_CONFIG from './domainConfig'
import { BACKEND_BASE_URL } from '@/utils/domainConfig.template'

// 根据不同书城定义不同参数
let TEMP_PLATFORM = DOMAIN_CONFIG.TEMP_PLATFORM // 三方平台id
let BILLING_APP = DOMAIN_CONFIG.BILLING_APP // 计费公号ID地址
let WXCP_URL = DOMAIN_CONFIG.WXCP_URL // 指定公号地址
let ICP_NO = DOMAIN_CONFIG.ICP_NO // ICP证号
let SP_NO = DOMAIN_CONFIG.SP_NO // SP证号
let WEB_AUTHOR_URL = DOMAIN_CONFIG.WEB_AUTHOR_URL
function genApiExport(config = {}) {
  // 证照号
  const ICP_CERT = `${config.icpNo || ICP_NO}`
  const SP_CERT = `${config.spNo || SP_NO}`
  const WXCP_URL_TEMPLATE = `${WXCP_URL}`
  // 三方平台Appid
  const PLATFORM_APPID = `${config.componentAppId || TEMP_PLATFORM}`
  // 获取计费公号appid
  const GET_BILLING_APPID = `${BILLING_APP}`
  const BILLING_APPID = config.billingAppId
  // 计费URL
  const BILLING_URL = `${config.payUrl ||
    DOMAIN_CONFIG.WEB_PAY_URL}?sn=%SN%&appid=%APPID%&date=%DATESTAMP%` //${Date.parse(new Date())}
  // 微信计费URL
  const WX_CERT_URL = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=%APPID%&redirect_uri=%BILLURL%&response_type=code&scope=snsapi_base&component_appid=${PLATFORM_APPID}&state=%STATE%#wechat_redirect` // encodeURIComponent()

  const AUTH_URL = config.authUrl || WEB_AUTHOR_URL
  const WEB_PAY_URL = config.payUrl || DOMAIN_CONFIG.WEB_PAY_URL // 公众号计费URL

  // 统计服务器 活动对象 暂不工作，统一定义到海草服务器
  // 统计服务器
  let LOG_SERVER = 'https://behavior-collection.cn-shanghai.log.aliyuncs.com/'

  const API = {
    queryHomePageInfo: '/open-api/v2/api/homepage/preview',
    blockList: '/open-api/v2/novel/info/block-novels',
    queryRankInfo: '/open-api/x1/api/rank/queryRankInfo?&novelType=1',
    searchNovel: '/open-api/x1/api/novel/searchNovel',
    queryNovelById: '/open-api/x1/api/novel/queryNovelById',
    queryRecommend: '/open-api/x1/api/recommend/queryRecommend?novelType=1',
    getClassification: '/open-api/x1/api/classify/getAllClassIfy',
    getClassById: '/open-api/x1/api/classify/queryClassIfyById?novelType=1',
    getRegUid: '/open-api/x1/api/user/sdkPartyReg',
    queryNovelChapterByChapterId: '/open-api/x1/api/chapter/queryH5NovelChapterContent',
    getCode: '/open-api/x1/api/idcode/sendCode',
    login: '/open-api/x1/api/user/mobileLogin',
    queryFirstClass: '/open-api/v2/bi/classify/new/query-initialization',
    queryFirstClassOfNovel: '/open-api/v2/bi/classify/new/query-selectLowerLevelNovelClassifyList',
    selectNovelInfoByClassId: '/open-api/v2/novel/info/query-selectNovelInfoByClassId',
    queryNovelDetail: '/read-api/v3/read/novel/info/getNovelInfoByNovelId',
    queryChapterDirectory: '/v1/api/novel/query-queryNovelChapterDirectory',
    homeColumns: '/open-api/v2/api/homepage1/columns',
    queryClassifyList: '/read-api/v3/read/novel/classify/getNovelClassifyInfoList',
    queryNovelRankList: '/read-api/v3/read/novel/rank/getNovelRankList',
    searchBlockName: '/read-api/v3/read/novel/info/getNovelInfoMatchList',
    queryhotWord: '/open-api/v2/novel/search/getHotWord',
    recommendation: '/open-api/v2/novel/search/recommendation',
    searchNovelName: '/open-api/v2/novel/info/query-selectNovelTitleMatch',
    queryH5NovelChapterArray: '/read-api/v3/read/novel/chapter/getNovelChapterByChapterId',
    queryRecommendation: '/open-api/v2/novel/bookshelf/recommendation',
    logstores: `${LOG_SERVER}/logstores/h5-logstore${isProd ? '' : '-test'}/track`,
    adstores: `${LOG_SERVER}/logstores/ad-logstore${isProd ? '' : '-test'}/track`,
    getWxOpenId: '/auth-api/v1/wxmp/oauth2/openid',
    queryMoneyList: '/open-api/x1/api/pay/rechargeMoney',
    walletList: '/pay-api/v1/wxmp/pay/record/list',
    queryOrderList: '/read-api/v3/read/novel/order/getConsumptionRecords',
    queryWXOrderStatus: '/open-api/x1/api/pay/wxPayQuery',
    queryHomeStaticData: '/open-api/v2/api/page/getStaticPageUrl',
    ttPay: '/pay-api/v1/wxmp/pay/record',
    getMyUserInfo: '/open-api/x1/api/user/queryUserInfo',
    addOrder: '/read-api/v3/read/novel/order/addOrder',
    addOrderOut: '/read-api/v3/read/novel/sub-chapter/batch',
    floatingScreen: '/activity-api/v1/activity-api/floating-screen/config',
    queryWxPayJson: '/pay-api/v1/wxmp/pay/wxpay-js',
    insertPage: '/open-api/v2/api/data/insertDataPage',
    insertNovelData: '/open-api/v2/api/data/insertDataNovel',
    isConcerned: '/open-api/v2/api/wx/isConcerned',
    limitTime: '/open-api/v2/api/limitedtimefree/novel',
    queryHomeInfo: '/read-api/v3/read/novel/homepage',
    readRecommend: '/open-api/v2/api/wxmp/phraseRecommend',
    selectNovelInfoByClassIdForWx: '/read-api/v3/read/novel/classify/getNovelClassifyInfoList',
    queryRechargeMoney: '/v1/wxmp/recharge/list',
    queryRechargeShowRules: '/v1/wxmp/recharge/list/rule',
    getSignCount: '/open-api/v2/api/sign/getSignCountForOpen',
    queryWxmpInfo: '/auth-api/v1/wxmp/oauth2/wxmp-info',
    qywxInfo: '/auth-api/v1/wxmp/oauth2/custom-agent',
    qywxfollowcode: '/wxmp-api/v2/api/wxmp/link/qr-code',
    feedback: '/open-api/x1/api/user/feedback/add',
    qwReport: '/v1/api/wxmp/qr-code/event/log',
    signSaveRecord: '/open-api/v2/api/pay/signSaveRecord',
    storeSign: '/open-api/v2/api/sign/storeSignForOpen',
    queryWxcpUserInfo: '/auth-api/v1/wxmp/oauth2/user-info',
    novelInfoChapterList: '/read-api/v3/read/novel/chapter/getNovelChapterListByNovelId',
    templateInfo: '/v1/wxmp/activity/template/info',
    rechargeCount: '/v1/wxmp/activity/user/recharge-count',
    drawCount: '/v1/api/wxmp/gift/draw-count',
    receiveCoin: '/v1/api/wxmp/gift/coin',
    vipCharge: '/v1/wxmp/member',
    connectConfig: '/v1/wxmp/customer-support/config',
    queryReadHistory: '/read-api/v3/read/novel/record/list',
    flowConfig: '/activity-api/v1/activity-api/info-flow/config',
    giftActivityList: '/activity-api/v1/activity-api/gift-activity/list',
    giftDetail: '/activity-api/v1/activity-api/gift-activity',
    receiveGift: '/activity-api/v1/activity-api/gift-activity/receive',
    saveSJ: '/read-api/v3/read/bookshelf',
    querySJList: '/read-api/v3/read/bookshelf/list',
    deleteSJ: '/read-api/v3/read/bookshelf/del',
    getConfig: '/auth-api/v2/api/config/getDomain',
    getLastChapter: '/v1/api/order/last-chapter',
    recordRefresh: '/v3/read/novel/record/refresh',
    getPayOpenId: '/auth-api/v2/api/collection/get-pay-openid',
    getRecommendList: '/read-api/v3/read/novel/page-foot'
  }

  Object.keys(API).forEach(k => {
    if (API[k].indexOf('/') === 0) {
      API[k] = (config.backendApiBaseUrl || DOMAIN_CONFIG.BACKEND_BASE_URL) + API[k]
    }
  })

  return {
    API,
    GET_BILLING_APPID,
    BILLING_APPID,
    ICP_CERT,
    SP_CERT,
    WXCP_URL_TEMPLATE,
    PLATFORM_APPID,
    BILLING_URL,
    WX_CERT_URL,
    AUTH_URL,
    WEB_PAY_URL,
    PAY_SERVER_BASE_URL: config.backendApiBaseUrl || DOMAIN_CONFIG.BACKEND_BASE_URL
  }
}

function updateApiDomain(c) {
  config = genApiExport(c)
  Object.assign(API, config.API)
}

let config = genApiExport()
const API = config.API

function getOtherApiData() {
  return config
}

export { API, getOtherApiData, updateApiDomain }
