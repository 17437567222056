
const RouterService = {
  beforeCallbacks: {},
  afterCallbacks: {},
  onBefore(name, fn) {
    this.beforeCallbacks[name] = this.beforeCallbacks[name] || [];
    this.beforeCallbacks[name].push(fn);
  },
  async asyncTriggerBefore(name, _this) {
    const res = [];
    if (!this.beforeCallbacks[name]) {
      return res;
    }
    const arr = this.beforeCallbacks[name].concat([]);
    while (arr.length) {
      const fn = arr.shift();
      try {
        res.push((await fn.call(_this)));
      } catch (e) {
        console.error(e);
      }
    }
    return res;
  },
  syncTriggerBefore(name, _this) {
    const res = [];
    if (!this.beforeCallbacks[name]) {
      return res;
    }
    const arr = this.beforeCallbacks[name].concat([]);
    while (arr.length) {
      const fn = arr.shift();
      try {
        res.push(fn.call(_this));
      } catch (e) {
        console.error(e);
      }
    }
    return res;
  },
  hookComponent(Component) {
    const _this = this;
    function hook(name) {
      const old = Component.prototype[name];
      Component.prototype[name] = function () {
        // console.log('hooked',name);
        _this.asyncTriggerBefore(name, this).then(() => {
          old && old.call(this);
        });
      };
    }
    ['componentWillMount', 'componentDidMount', 'componentDidShow'].forEach(name => hook(name));

    const oldRender = Component.prototype.render;
    Component.prototype.render = function () {
      const res = _this.syncTriggerBefore('render', this);
      for (let i = 0; i < res.length; i++) {
        if (typeof res[i] !== "undefined") {
          return res[i];
        }
      }
      return oldRender.call(this);
    };

    return Component;
  }
};

export default RouterService;