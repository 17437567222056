import { getAppId } from './utils'

// 是否为正式环境
const isProd = true

// 新版的配置文件
const GLOBAL_CONFIG = {
  // 路径缓存
  path_cache: getAppId() + 'path_cache',
  // 渠道
  wy_cno: getAppId() + 'wy_cno',
  // 平台
  wy_platform: getAppId() + 'wy_platform',
  // 分栏Id
  wy_columnId: getAppId() + 'wy_columnId',
  // 历史记录
  wy_novel_history: getAppId() + 'wy_novel_history',
  // 皮肤
  wy_skin_mode: getAppId() + 'wy_skin_mode',
  // 字体尺寸
  wy_novel_size: getAppId() + 'wy_novel_size',
  // 小说
  wy_novel: getAppId() + 'wy_novel',
  // 书架列表
  sj_novel: getAppId() + 'shujia_novel',
  // 小说夜间/白天模式
  wy_novel_day: getAppId() + 'wy_novel_day',
  // 小说白天模式的皮肤
  wy_novel_skin: getAppId() + 'wy_novel_skin',
  // 小说章节阅读记录
  wy_novel_chapter: getAppId() + 'wy_novel_chapter',
  // 存放小说白天皮肤的数组
  wy_novel_skin_val: ['readColor1', 'readColor2', 'readColor3', 'readColor4', 'readColor5'],
  // 存放小说夜间皮肤的值
  wy_novel_skin_night_val: 'readColor6',
  // 默认小说字体大小
  wy_novel_font_size: 40,
  // 小说最小字体
  wy_novel_min_font_size: 32,
  // 小说最大字体
  wy_novel_max_font_size: 100,
  wy_show_guide: getAppId() + 'wy_show_guide',
  // 小说阅读历史
  wy_read_history: getAppId() + 'wy_read_history',
  // 是否首次激活页面
  wy_first_active: getAppId() + 'wy_first_active', // 默认首次
  // uuid
  wy_uuid: getAppId() + 'wy_uuid',
  // openId是用户的唯一标识符
  wy_open_id: getAppId() + 'wy_open_id',
  // 用户信息
  wy_user_info: getAppId() + 'wy_user_info',
  // 小说对象
  novelKeyArrays: ['id', 'coverUrl', 'title', 'introduction', 'author', 'isFinish'],
  // sld
  wy_sld: getAppId() + 'wy_sld',
  wy_read_chapter: getAppId() + 'wy_read_chapter',
  wy_wxcp_info: getAppId() + 'wy_wxcp_info',
  wy_code: 'wy_code',
  // 缓存小说阅读状态
  wy_novel_autobuy: getAppId() + 'wy_novel_autobuy',

  // 重新缓存小说阅读历史
  wy_new_read_history: getAppId() + 'wy_new_read_history'
}

const cvp = {
  h5: {
    ver: '1.0.0112',
    plat: '3'
  }
}

const SUCCESS_OK = '0000'

// 切换小程序的各个渠道获取不同的数据，发版前一定要查看！
let mycon = cvp.h5

const GLOBAL_CONFIG_VALUE = {
  cno: getAppId(),
  version: mycon.ver,
  platform: mycon.plat
}

export { GLOBAL_CONFIG, GLOBAL_CONFIG_VALUE, isProd, SUCCESS_OK }
