import Taro, { showLoading as _showLoading, request as _request, hideLoading as _hideLoading, showToast as _showToast } from "@tarojs/taro-h5";
import { getAppId } from './utils';
import { GLOBAL_CONFIG_VALUE } from './config';

const getRequest = (url, params, success, fail) => {
  if (params.loading !== false) {
    _showLoading({
      title: '加载中...'
    });
  }
  if (!params.data) {
    params.data = {};
  }
  _request({
    url,
    data: {
      sdkVersion: GLOBAL_CONFIG_VALUE.version,
      ver: '1.0.0112',
      platform: GLOBAL_CONFIG_VALUE.platform,
      cno: getAppId(), // 默认是海草安卓渠道
      ...params.data
    },
    header: {
      'content-type': 'application/json',
      mode: 'no-cors', // 支持h5跨域
      appid: getAppId()
    }
  }).then(res => {
    if (params.loading !== false) {
      _hideLoading();
    }
    const data = res.data;
    if (data.errcode === 0 || data.code === '0000') {
      let result = data.data;
      typeof success === 'function' && success(result);
    } else if (data.code === '5013') {
      // 版权问题特殊处理
      typeof fail === 'function' && fail(data.code, data.msg);
    } else {
      if (res.data.msg !== '微信鉴权失败') {
        _showToast({
          title: res.data.msg,
          icon: 'none'
        });
        typeof fail === 'function' && fail(data.code, data.msg);
      } else {
        typeof success === 'function' && success();
      }
    }
  }, err => {
    if (params.loading !== false) {
      _hideLoading();
    }
    fail && fail(err);
  });
};

// 微信公号暂无日志记录
// const logRequest = (url, params) => {
//   Taro.request({
//     url,
//     data: {
//       APIVersion: '0.6.0',
//       ...params
//     },
//     header: {
//       'content-type': 'application/json',
//       mode: 'no-cors' // 支持h5跨域
//     }
//   }).then(res => {
//   }, (err) => {
//     // console.log(err)
//   })
// }

const postRequest = (url, params, success, fail) => {
  if (params.loading !== false) {
    _showLoading({
      title: '加载中...'
    });
  }
  if (!params.data) {
    params.data = {};
  }

  _request({
    url,
    data: {
      sdkVersion: GLOBAL_CONFIG_VALUE.version,
      platform: GLOBAL_CONFIG_VALUE.platform,
      cno: getAppId(),
      ...params.data
    },
    header: {
      'content-type': 'application/json',
      mode: 'no-cors', // 支持h5跨域
      appid: getAppId()
    },
    method: 'POST'
  }).then(res => {
    if (params.loading !== false) {
      _hideLoading();
    }
    const { data = {} } = res;
    if (data.errcode == 0 || data.code === '0000') {
      let result = data.data;
      success(result);
    } else {
      _showToast({
        title: data.msg || '网络异常',
        icon: 'none'
      });
    }
  }, err => {
    if (params.loading !== false) {
      _hideLoading();
    }
    typeof fail === 'function' && fail(err);
  });
};

export { getRequest, postRequest
// logRequest
};