import { updateApiDomain, API, getOtherApiData } from "../utils/apiList";
import request from "../utils/request";
import { getAppId } from "../utils/utils";
let isApiUpdated = false;
let fetchingPromise = null;
const timeout = 3000;
const UserService = {
  async init() {
    console.log('UserService.init');
    await this.initConfig();
  },
  isReady() {
    return !!isApiUpdated;
  },
  async initConfig() {
    if (isApiUpdated) {
      //已经更新过了
      return;
    }
    if (!fetchingPromise) {
      //正在更新中，复用promise。否则，创建promise
      fetchingPromise = Promise.race([this.fetchConfig().then(() => {
        fetchingPromise = null;
        isApiUpdated = true;
      }).catch(err => {
        // 捕获异常自己吃掉，如果失败就在没更新的情况下用默认域名
        fetchingPromise = null;
      }),
      // 超时逻辑，3秒不返回直接return，不能阻塞正常业务
      new Promise(r => setTimeout(() => {
        fetchingPromise = null;
      }, timeout))]);
    }
    await fetchingPromise;
    isApiUpdated = true;
  },
  async fetchConfig() {
    // todo: 调接口获取当前appId的baseDomain
    const data = await request({
      url: API.getConfig,
      data: {
        appId: getAppId()
      }
    });
    updateApiDomain(data.data);
    console.log('config updated', getOtherApiData());
  }

};

export default UserService;